<template>
  <v-container fill-height justify-content-start :class="{'pa-2': $vuetify.breakpoint.xsOnly}">
    <v-layout column>
      <v-layout row wrap justify-start class="ma-0 max-height-100">
        <v-flex xs9 class="max-height-100">
          <v-layout column class="pa-4 max-height-100 overflow-y-auto">
            <!-- TITLE -->
            <v-layout align-center class="flex-none">
              <v-btn :class="{'ma-1': xsOnly}" :fab="mdAndUp" icon text @click="$router.push({ name: routeNames.guest.name, params: { id: $route.params.id } })"><v-icon color="grey" :large="mdAndUp">mdi-arrow-left</v-icon></v-btn>
              <h2 class="display-2 raleway grey--text mt-3 mb-4">{{$t('balance_adjustments.name')}}</h2>
            </v-layout>
            <v-layout column>
              <!-- GUEST INFO -->
              <v-layout v-if="guests.read" wrap align-center class="my-1">
                <span class="grey--text mr-2 headline">{{ getGuestFullName(guests.read) }}</span>
                <v-chip v-if="booking" class="subtitle-1 my-1 mr-2" label>
                  <v-icon class="mr-2">{{ isParkBooking(booking) ? 'mdi-ticket' : 'mdi-bed' }}</v-icon>
                  <span>{{ isParkBooking(booking) ? $t('bookings.park_visitor') : $t('bookings.hotel_guest') }}</span>
                </v-chip>
              </v-layout>
              <!-- SEARCH AND RESET -->
              <v-layout justify-space-between align-center>
                <v-flex xs12 sm6 md5 lg4>
                  <v-text-field
                    autocomplete="off"
                    v-model="search"
                    :label="$t('packages.package_name')"
                    append-icon="mdi-magnify"
                    color="primary"
                    hide-details
                    class="mb-2 mt-5"
                    clearable
                    outlined
                  />
                </v-flex>
                <v-btn v-if="guests.adjustments.length" outlined color="primary" @click="$store.commit('guests/resetAdjustments')" class="my-2">
                  {{ $t('actions.reset_adjustments') }}
                </v-btn>
              </v-layout>
              <!-- CURRENCY LIST -->
              <package-list :search="search ? sanitize(search) : null" :extendedAccount="extendedAccount" storeModule="guests" :list="guests.adjustments" balanceAdjustment />
            </v-layout>
            <!-- SUMMARY -->
            <adjustment-summary v-if="extendedAccount" @accept="handleContinue()" :extendedAccount="extendedAccount" />
            <supervisor-modal :triggerOpen="openSupervisorModal" @finished="postAdjustments()"/>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-layout>
  </v-container>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import { getGuestFullName } from '@/utils/GuestUtils.js'
import routeNames from '@/router/routes'
import vuetifyMixins from '@/mixins/Vuetify'
import searchMixins from '@/mixins/Search'

export default {
  data () {
    return {
      routeNames,
      getGuestFullName,
      table: 'AccountBalanceAdjustment',
      headers: [
        { text: this.$t('packages.name'), path: 'currency.name', show: true },
        { text: this.$t('packages.fields.current'), path: 'balance', show: true },
        { text: this.$t('packages.fields.max'), path: 'max', show: true, getValue: (item) => this.getPackageField({ id: item.currency.id, field: 'maxTokenAmountOnAccount' }) }
      ],
      openSupervisorModal: null
    }
  },
  computed: {
    ...mapState(['guests', 'packages']),
    ...mapGetters({
      isParkBooking: 'configuration/isParkBooking',
      getPackage: 'packages/getPackage',
      isSupervisor: 'shifts/isSupervisor',
      getPackageField: 'packages/getPackageField'
    }),
    booking () {
      return this.guests.read ? this.guests.read.booking : null
    },
    extendedAccount () {
      return this.guests.read ? this.guests.read.extendedAccount : null
    }
  },
  methods: {
    refreshData () {
      this.$store.dispatch('packages/list')
      this.$store.dispatch('guests/get', { id: this.$route.params.id, save: true, decorate: true })
    },
    handleContinue () {
      if (!this.isSupervisor) {
        this.openSupervisorModal = new Date()
      } else {
        this.postAdjustments()
      }
    },
    postAdjustments () {
      this.$store.dispatch('guests/postAdjustments').then(() => {
          this.$store.commit('state/setMessage', { text: this.$t('balance_adjustments.success'), color: 'success' })
          this.$store.commit('guests/resetAdjustments')
          this.$router.push({ name: routeNames.ticket.name, params: { id: this.booking.id } })
        }).catch((error) => {
          if (error.body.message === '{error=NO_OPEN_SHIFT}') {
            if (!this.isSupervisor) {
              this.$store.commit('state/setMessage', { text: this.$t('error.no_open_shift_supervisor'), color: 'error' })
            }
          } else {
            this.$store.commit('state/setMessage', { text: this.$t('balance_adjustments.errors.generic'), color: 'error' })
          }
          this.refreshData()
        })
    }
  },
  created () {
    this.refreshData()
  },
  components: {
    ItemList: () => import('@/components/app-components/listing/ItemList.vue'),
    ShiftRequired: () => import('@/components/view-components/ShiftRequired.vue'),
    PackageList: () => import('@/components/view-components/package-order/PackageList.vue'),
    SupervisorModal: () => import('@/components/view-components/modals/SupervisorModal.vue'),
    AdjustmentSummary: () => import('@/components/view-components/AdjustmentSummary.vue')
  },
  mixins: [
    vuetifyMixins,
    searchMixins
  ],
  beforeDestroy () {
    this.$store.commit('guests/setRead', null)
    this.$store.commit('packages/setList', [])
    this.$store.commit('guests/resetAdjustments')
  }
}
</script>